@import "../../media.css";
@import "../../mixins.css";
@import "../../colors.css";

.component {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: $spacingXs;
  animation: fadein 0.35s ease-in;
  @mixin background_transparent_grey;
}

.image {
  width: 92px;
  margin-right: $spacingNudge;
}

.imageWrapper {
  display: flex;
  align-items: center;
  min-height: 124px;
  position: relative;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 $spacingXs;
  padding: $spacingSm 0;
  white-space: normal;
  @mixin text_tertiary;
  @mixin metaDescriptionText;
  font-size: 13px;
  width: calc(100% - 92px);
}

.noImage {
  margin: 0 auto;
}

.modalTextWrapper {
  padding: $spacingMd;
  margin: 0;
  @mixin text_secondary;

  @media (--tablet-and-desktop) {
    font-size: 16px;
  }
}

.modalTextTitle {
  @mixin metaTitleText;
  @mixin text_primary;
  margin-top: -$spacingXs;
  text-transform: capitalize;

  @media (--tablet-and-desktop) {
    font-size: 18px;
  }
}

.textTitle {
  @mixin metaTitleText;
  @mixin text_primary;
  margin-top: -$spacingXs;
  text-transform: capitalize;
  padding-bottom: $spacingNudge;
}

.closeButtonCTA {
  @mixin metaTitleText;
  @mixin text_tertiary;
  position: absolute;
  right: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  width: 44px;
  height: 44px;
  svg {
    font-size: 16px;
    @mixin icon_color_secondary;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
​
  100% {
    opacity: 1;
  }
}

.fitEducatorVideoBtn {
  border: none;
  position: absolute;
  z-index: 1;
  background-color: $black;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% - 4px);

  svg {
    font-size: $iconSizeXl;
    color: white;
  }
}
