@import "../media.css";
@import "../typography.css";
@import "../mixins.css";
@import "../colors.css";

.component {
}

.container {
  align-items: center;
  display: flex;
  width: 100%;
  position: relative;
}

.forColorLabel {
  font-size: 0;
  color: transparent;
}

.toggleItem {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

[data-keyboard="true"] {
  .toggleItem:focus + * {
    @mixin default_ada_focus
  }
}

[data-keyboard="false"] {
  .toggleItem:focus + * {
    box-shadow: none;
  }
}

.circleLarge {
  box-sizing: border-box;
  @mixin border_input;
  float: left;
  height: 20px;
  width: 20px;
  min-width: 20px;
  position: relative;
  top: 0;
  margin-right: $spacingXs;
  @mixin background_primary;
  border-radius: 20px;
  &:hover {
    @mixin background_alternate;
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      background: transparent;
      top: calc(50% - 5px);
      left: calc(50% - 6px);
      border: 2px solid $white1;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      transform-origin: center center;
      box-sizing: inherit;
    }
  }

  &.selected {
  @mixin background_alternate;
  border-color: $grey7;

    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      background: transparent;
      top: calc(50% - 5px);
      left: calc(50% - 6px);
      border: 2px solid $white1;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      transform-origin: center center;
      box-sizing: inherit;
    }
  }

  &.colorCircle {
    width: 24px;
    height: 24px;
  }
  /* Force background colors for color filters */
  &.black {
    background-color: $base_color_black;
  }
  &.blue {
    background-color: $base_color_blue;
    &:after { border-color: $grey7; }
  }
  &.brown {
    background-color: $base_color_brown;
  }
  &.navyblue {
    background-color: $base_color_navy_blue;
  }
  &.green {
    background-color: $base_color_green;
  }
  &.grey {
    background-color: $base_color_grey;
    &:after { border-color: $grey7; }
  }
  &.khaki {
    background-color: $base_color_khaki;
    &:after { border-color: $grey7; }
  }
  &.multi {
    background-color: none;
    background-image: $base_color_multi;
    &:after { border-color: $grey7; }
  }
  &.orange {
    background-color: $base_color_orange;
    &:after { border-color: $grey7; }
  }
  &.pink {
    background-color: $base_color_pink;
    &:after { border-color: $grey7; }
  }
  &.purple {
    background-color: $base_color_purple;
  }
  &.red {
    background-color: $base_color_red;
  }
  &.white {
    background-color: $base_color_white;
    &:after { border-color: $grey7; }
  }
  &.yellow {
    background-color: $base_color_yellow;
    &:after { border-color: $grey7; }
  }
}

.fitEdItem {
  display: flex;
  flex-direction: column-reverse;
  max-width: 260px;
  padding-right: 0;
  &:hover {
    .circleLarge {
      box-sizing: border-box;
      @mixin border_input;
      float: left;
      position: relative;
      top: 0;
      margin-right: $spacingXs;
      @mixin background_alternate;
      border-radius: 20px;
      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        top: calc(50% - 5px);
        left: calc(50% - 6px);
        border: 2px solid $white1;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        transform-origin: center center;
        box-sizing: inherit;
      }
    }
  }
}

.checkbox {
  min-width: 72px;
  padding-right: $spacingXs;
  padding-bottom: $spacingXs;
  > label {
    cursor: pointer;
  }
  &:hover {
    .circleLarge {
      box-sizing: border-box;
      @mixin border_brand;
      float: left;
      position: relative;
      top: 0;
      margin-right: $spacingXs;
      border-radius: 20px;
    }
    span{
      font-weight: 700;
    }
  }
}

.small {
  min-width: 36px;
  padding: $spacingNudge $spacingNudge $spacingNudge 0;
  @media (--smartphone-and-tablet) {
    min-width: 46px;
  }
}

.large {
  width: 33.3%;
  max-width: 120px;
  padding: 0 $spacingNudge $spacingNudge 0;
}

.selected {
  @mixin background_alternate;
  border-color: $grey7;

  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 8px;
    background: transparent;
    top: calc(50% - 5px);
    left: calc(50% - 8px);
    border: 3px solid $white1;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    transform-origin: center center;
    box-sizing: inherit;
  }
}

.payments {
  @mixin border_primary;
  &.selectedItem {
    @mixin border_brand;
  }
  padding: 15px 30px;
  cursor: pointer;
  &:hover {
    @mixin border_brand;
  }
  *:hover {
    cursor: pointer;
  }
  margin-right: 10px;
  span {
    font-size: 12px;
    @mixin text_secondary;
  }

  .selected {
    &:after {
      content: none;
    }
    @mixin background_primary;
    border: 3px solid $accent2;
  }
}

.vertical {
  &.component {
    padding: 20px 0 20px 20px;
    @mixin border_bottom_primary;
    cursor: pointer;
    @mixin paragraphText;
  }
  .selected ~ span {
    @mixin text_brand;
    &:before {
      content: '\2713';
      padding-right: 10px;
    }
  }

}

.smallCircleInline {
  @mixin metaDescriptionText;
  dl {
    display: flex;
    justify-content: space-between;
    @media (--tablet) {
      min-width: 350px;
    }
    @media (--smartphone) {
      width: 100%;
    }
  }

  .circleLarge {
    width: 20px;
    height: 20px;
    @mixin background_secondary;
  }

  .selected {
    @mixin background_alternate;
  }
}

.disabledCheckbox {
  opacity: 0.33;
  > label {
    cursor: default;
  }
  &:hover {
    text-decoration: none;
  }
}

.soldOut {
  opacity: 0.33;
  text-decoration: none;
}
