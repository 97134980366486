@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";
@import "../../colors.css";
@import "../../design_tokens.css";

.component {
  padding-bottom: $spacingSm;
    @media (--smartphone-and-tablet) {
      padding-bottom: 0;
    }
    :global .markdown-component {
      p {
        margin: 0;
        @mixin text_primary;
        @media (--smartphone-and-tablet) {
          padding-top: $spacingSm;
          margin: 0 0 $spacingNudge;
          @mixin captionDescriptionText;
        }
      }
    }
}

.productHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (--desktop) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.name {
  @mixin headerText;
  width: 70%;
  @mixin text_primary;
  letter-spacing: -0.56px;
  margin-bottom: $spacingXs;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: $spacingNudge;
  @media (--desktop) {
    @mixin titleText;
    font-weight: 500;
    width: 100%;
  }
}

.bottomPadding {
  padding-bottom: $spacingLg;
}

.price {
  @mixin bodyTitleText;
  letter-spacing: -0.28px;
  position: relative;
  align-self: start;
  margin: 0;
  padding-bottom: $spacingNudge;

  @media (--smartphone-and-tablet) {
    padding-top: 0;
    @mixin metaTitleText;
  }
}

.onSaleContainer {
  padding: $spacingNudge 0 $spacingXs;
  display: flex;
  flex-direction: column;
  @media (--desktop) {
    flex-direction: row;
  }
}

.salePrice {
  @mixin text_promo;
}

.fullPrice {
  @mixin text_primary;
}

.strikethroughPrice {
  @mixin text_secondary;
  text-decoration: line-through;
  margin-right: 10px;
}

.description {
  @mixin metaDescriptionText;
  padding-top: 0;
  margin-top: 0;
}

.finalSale {
  @mixin bodyTitleText;
  &:before {
    content: '\2015';
    margin: 0 $spacingXs;
  }
  @media (--smartphone-and-tablet) {
    @mixin captionTitleText;
    text-align: right;
    padding-top: 0;
    &:before {
      content: '';
      margin: 0;
    }
  }
}

.finalSaleOnSale {
  @mixin text_error;
}

.short {
  .name {
    @mixin metaTitleText;
    float: left;
    margin: 0;
    letter-spacing: 0;
    max-width: 240px;
  }
  .price {
    @mixin metaTitleText;
    float: right;
    margin: 0;
  }
  .strikethroughPrice {
    margin-right: 2px;
  }
  .color {
    @mixin metaDescriptionText;
    @mixin text_secondary;
    clear: both;
    text-transform: capitalize;
  }
}

.pdpBadges {
  @mixin badgeTitleText;
  @mixin text_primary;
  text-transform: uppercase;
  padding-bottom: $spacingSm;
}

.promoPriceContainer {
  @mixin border_top_primary;
  @mixin border_bottom_primary;
  margin: $spacingXs 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (--smartphone-and-tablet) {
    margin-top: $spacingXs;
    margin-bottom: 0;
  }

  .price {
    margin: $spacingXs 0;
  }
}

.promoPrice {
  font-size: 13px;
  @media (--desktop) {
    font-size: 15px;
  }
}

.promoCodeButton {
  @mixin accessible_button;
  @mixin text_promo;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.relatedProductLinksContainer {
  .relatedProductLinksTabGroup {
    @mixin border_bottom_primary;
    display: flex;
    padding-left: 1rem;
   
    @media (--desktop) {
      margin-bottom: 1rem;
      margin-top: -4px;
    }

    button {
      padding: 0;
    }

    div > div {
      padding: 0;
    }
  }

  .relatedProductLinksTabGroup > div:first-child {
    padding-bottom: 0;
    overflow-y: hidden;
  }

  .relatedProductNavItem {
    &:after {
      height: 2px !important;
    }
  }

  .relatedProductLinksAnchor {
    @mixin metaTitleText;
    height: 40px;
    margin: 0;
    padding: 14px 10px;
    color: $grey4;
  }

  .relatedProductLinksAnchor:focus {
    font-weight: 600;
  }

  .relatedProductLinksAnchorSelected {
    color: $grey7;
    font-weight: 600;
  }
}
